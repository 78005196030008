import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "glushkov_mom" }) {
    ...NewsInfoEn
    innerImages {
      maksimov {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p><Link to="/en/players/glushkov">Nikita Glushkov</Link>, a client of Catenaccio Sports Agency, was a man of the match in an expected Russian cup win of Baltika Kaliningrad against title contenders Lokomotiv Moscow. Glushkov had had a great game and scored the winning penalty as well.</p>
        </div>
      </div>
    )
  }
}